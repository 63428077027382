<template>
  <Layout>
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
      >
        <el-form-item>
          <el-input
            v-model="formData.activityName"
            clearable
            placeholder="活动名称"
          />
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="formData.globalStatus"
            placeholder="状态"
            clearable
          >
            <el-option
              v-for="(item, index) in promotionStateList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="formData.channelId"
            placeholder="渠道类型"
            clearable
          >
            <el-option
              v-for="(item, index) in promotionChannelList"
              :key="index"
              :label="item.channelName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="formData.goodsType"
            placeholder="商品类型"
            clearable
          >
            <el-option
              v-for="(item, index) in goodsTypeList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
      <template #right>
        <el-button
          type="primary"
          @click="handleCreatRedeemCode"
        >
          新建兑换码
        </el-button>
      </template>
    </BHeader>
    <el-table
      ref="table"
      v-loading="listInfo.loading"
      :data="listInfo.list"
      stripe
    >
      <el-table-column
        prop="activityName"
        label="兑换活动名称"
        width="120px"
      />
      <el-table-column
        prop="goodsType"
        label="商品类型"
      >
        <template #default="{row}">
          {{ (goodsTypeMap[row.goodsType] || {}).name || '未知' }}
        </template>
      </el-table-column>
      <el-table-column
        label="渠道类型"
        width="120px"
      >
        <template #default="{row}">
          {{ (promotionChannelMap[row.channelId] || {}).channelName || '未定义' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="channelName"
        label="渠道名称"
      />
      <el-table-column
        label="发放数量"
      >
        <template #default="{row}">
          <el-button
            type="text"
            @click="handleViewItem(row)"
          >
            {{ row.codeNum }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        label="已兑换"
      >
        <template #default="{row}">
          <el-button
            type="text"
            @click="handleViewItem(row)"
          >
            {{ row.redeemed || 0 }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        prop="globalStatus"
        label="状态"
      >
        <template #default="{row}">
          {{ (promotionStateMap[row.globalStatus] || {}).name || '未定义' }}
        </template>
      </el-table-column>
      <el-table-column
        label="类型"
      >
        <template #default="{row}">
          {{ (codeTypeMap[row.codeType] || {}).name || '未定义' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        width="160px"
      >
        <template #default="{row}">
          {{ dataFomat(row.createTime) }}
        </template>
      </el-table-column>
      <el-table-column
        label="可用时间"
        width="310px"
      >
        <template #default="{row}">
          {{ dataFomat(row.startUsedTime) }} -- {{ dataFomat(row.endUsedTime) }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="220px"
        fixed="right"
      >
        <template #default="{row}">
          <BTextButton
            title="编辑"
            icon="fa fa-pencil"
            @click="handleEditItem(row)"
          />
          <BTextButton
            :loading="row.loading"
            :title="(promotionStateMap[row.globalStatus] || {}).name || '未定义'"
            :icon="`fa ${row.globalStatus === 2 ? 'fa-check-circle' : 'fa-ban '}`"
            @click="handleToggleItem(row)"
          />
          <BTextButton
            :loading="row.deleteLoading"
            title="删除"
            icon="fa fa-trash-o"
            @click="handleDeleteItem(row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="listInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryDataList"
    />
  </Layout>
</template>

<script>
import {
  promotionStateList,
  promotionStateMap
} from '@/utils/selectOptions.js'

import {
  getAllChannelType,
  getRedeemActivityList,
  updateRedeemActivityStatus,
  deleteRedeemActivity
} from '@/api/CouponsAndRedeemCode.js'

export default {
  name: 'RedeemCodeNoCache',
  data () {
    return {
      promotionStateList,
      promotionStateMap,
      promotionChannelList: [],
      promotionChannelMap: {},
      goodsTypeList: [
        { name: 'vip', value: 1 },
        { name: '金币', value: 2 },
        { name: '时长卡', value: 3 }
      ],
      goodsTypeMap: {
        1: { name: 'vip', value: 1 },
        2: { name: '金币', value: 2 },
        3: { name: '时长卡', value: 3 }
      },
      codeTypeMap: {
        1: { name: '单次码', value: 1 },
        2: { name: '多次码', value: 2 }
      },
      formData: {
        pageNum: 1,
        pageSize: 20
      },
      listInfo: {
        list: [],
        total: 0,
        loading: false
      }
    }
  },
  created () {
    this.handleSearch()
    this.getAllChannelType()
  },
  methods: {
    dataFomat (date) {
      if (date === '0000-00-00 00:00:00') { return '-' }
      return date
    },
    getAllChannelType () {
      getAllChannelType({ activityType: 1 })
        .then(res => {
          if (res.code === 200) {
            this.promotionChannelList = res.data.list || []
            this.promotionChannelMap = this.promotionChannelList.reduce((map, item) => {
              map[item.id] = item
              return map
            }, {})
          }
        })
    },
    queryDataList () {
      this.listInfo.loading = true
      getRedeemActivityList({ ...this.formData })
        .then(res => {
          if (res.code === 200) {
            this.listInfo.list = res.data.list || []
            this.listInfo.total = res.data.total || 0
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    },
    handleSearch () {
      this.formData.pageNum = 1
      this.queryDataList()
    },
    handleCreatRedeemCode () {
      this.$router.push({
        name: 'RedeemCodeCreate'
      })
    },
    handleViewItem (row) {
      this.$router.push({
        name: 'RedeemCodeList',
        query: {
          id: row.id,
          name: row.activityName,
          total: row.codeNum,
          start: row.startUsedTime,
          end: row.endUsedTime
        }
      })
    },
    handleEditItem (row) {
      this.$router.push({
        name: 'RedeemCodeEdit',
        query: {
          id: row.id
        }
      })
    },
    handleToggleItem (row) {
      if (row.globalStatus !== 2) return false
      const TIPS = '此操作将提前结束活动， 是否继续?'
      this.$confirm(TIPS, '提示', { type: 'warning ' })
        .then(() => {
          this.$set(row, 'loading', true)
          updateRedeemActivityStatus({
            id: row.id,
            activityStatus: 2
          }).then(res => {
            if (res.code === 200) {
              this.queryDataList()
            }
          }).finally(() => {
            this.$set(row, 'loading', false)
          })
        })
    },
    handleDeleteItem (row) {
      if (row.globalStatus === 2) {
        this.$message.error('活动进行中, 不可删除')
        return false
      }
      this.$confirm('此操作将删除活动, 是否继续', '提示', { type: 'warning ' })
        .then(() => {
          this.$set(row, 'deleteLoading', true)
          deleteRedeemActivity({
            id: row.id
          }).then(res => {
            if (res.code === 200) {
              this.$message.success('删除成功')
              this.handleSearch()
            }
          }).finally(() => {
            this.$set(row, 'deleteLoading', false)
          })
        })
    }
  }
}
</script>

<style>

</style>
